$( document ).ready(function() {
    _pagetitlebanner();
});
const _pagetitlebanner = () => {
    if ( $('.pagetitlebanner-video-wrapper .js-play-video').length > 0 ) {
        $('.pagetitlebanner-video-wrapper .js-play-video').on('click', function (e) {
            e.preventDefault();
            var button = $(this),
                video = button.attr('data-video'),
                type = button.attr('data-type');
            if ( type == "video" ) {
                $.magnificPopup.open({
                    items: {
                        src: $(`<div class="mfp-figure mfp-figure-video">
                            <button title="Close (Esc)" type="button" class="mfp-close">×</button>
                            <div class="mfp-video">
                                <div class="video-wrapper">
                                    <video width="320" height="240" src="`+video+`" autoplay controls></video>
                                </div>
                            </div>
                        </div>`),
                        type: 'inline',
                        removalDelay: 500,
                        mainClass: 'mfp-zoom-in',
                        midClick: true
                    }
                });
            }
            else if ( type == "youtube" ) {
                $.magnificPopup.open({
                    items: {
                        src: $(`<div class="mfp-figure mfp-figure-video">
                            <button title="Close (Esc)" type="button" class="mfp-close">×</button>
                            <div class="mfp-video">
                                <div class="video-wrapper">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/`+video+`?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>`),
                        type: 'inline',
                        removalDelay: 500,
                        mainClass: 'mfp-zoom-in',
                        midClick: true
                    }
                });
            }
            else if ( type == "vimeo" ) {
                $.magnificPopup.open({
                    items: {
                        src: $(`<div class="mfp-figure mfp-figure-video">
                            <button title="Close (Esc)" type="button" class="mfp-close">×</button>
                            <div class="mfp-video">
                                <div class="video-wrapper">
                                    <iframe src="https://player.vimeo.com/video/`+video+`?autoplay=1" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>`),
                        type: 'inline',
                        removalDelay: 500,
                        mainClass: 'mfp-zoom-in',
                        midClick: true
                    }
                });
            }
        });
    }
}